import React from "react";
import Iframe from "react-iframe";
const MRPhotos = () => {
  return (
    <div>
      <Iframe
        url='https://mruns.smugmug.com'
        width='100%'
        id='myId'
        className='myClassname'
        display='initial'
        position='relative'
        height='960px'
      />
    </div>
  );
};

export default MRPhotos;
