import React, { useState, useEffect } from "react";

import axious from "axios";

import StravaDataTable from "./StravaDataTable";

const SplitTable = (props) => {
  const [stravaData, setStravaData] = useState([]);
  const [id, setId] = useState(props.id);
  const url = `http://www.silwal.com/api/strava/`;
  useEffect(() => {
    if (id !== undefined) {
      const options = {
        method: "get",
        url: url + id,
        responseType: "json",
      };
      axious(options).then(function (reponse) {
        setStravaData(reponse.data);
      });
    }
  }, [id]);

  return (
    <>
      {stravaData.map((data) => (
        <StravaDataTable innerval={data.splits_metric} />
      ))}
    </>
  );
};

export default SplitTable;
//console.log(data.splits_metric[0])
