import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import momentduration from "moment-timezone";


const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
});

export default function StravaDataTable(props) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Distance in KM</TableCell>
            <TableCell align='right'>Time</TableCell>
            <TableCell align='right'>Avg Speed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.innerval !== undefined &&
            props.innerval.map((row) => (
              <TableRow key={row.split}>
                <TableCell scope='right'>
                  {parseFloat(row.distance * 0.001).toFixed(1)}
                </TableCell>
                <TableCell align='right'>
                  {momentduration
                    .duration(row.elapsed_time, "second")
                    .format("hh:mm:ss")}
                </TableCell>
                <TableCell align='right'>{row.average_speed}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
