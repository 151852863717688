import React from "react";
import { Switch, Route } from "react-router-dom";

import MainView from "./components/MainView";
import MRPhotos from "./components/MRPhotos";
import Podcast from "./components/Podcast";
import ViewStravaData from "./components/ViewStravaData";
import DiscountCode from "./components/DiscountCode";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import PostDetail from "./components/PostDetail";

import RunSignupCalendar from "./components/RunSignUpCalendar"

function App() {
  return (
    <div>
      <NavBar />

      <Switch>
        <Route exact path='/' render={() => <MainView />} />
        <Route exact path='/photos' component={MRPhotos} />
        <Route exact path='/podcast' render={() => <Podcast />} />
        <Route exact path='/strava' render={() => <ViewStravaData />} />
        <Route exact path='/runsignup' render={() => <RunSignupCalendar />} />
        <Route exact path='/discount' render={() => <DiscountCode />} />

        <Route
          exact
          path='/detail/:id'
          render={(props) => (
            <PostDetail {...props} title={`Detail Page`} hasData='true' />
          )}
        />
      </Switch>

      <Footer />
    </div>
  );
}

export default App;
