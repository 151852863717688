import React from "react";
import Iframe from "react-iframe";
const Podcast = () => {
  return (
    <div>
      <Iframe
        url='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/269893783&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
        width='100%'
        id='myId2'
        className='myClassname'
        display='initial'
        position='relative'
        height='960px'
        allowFullScreen
      />
    </div>
  );
};

export default Podcast;
