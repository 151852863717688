import React, { useState, useEffect } from "react";
import { CardMedia } from "@material-ui/core";
import axious from "axios";
import Loading from "./Loading";

const PostMediaCard = (props) => {
  const [loading, setLoading] = useState(true);
  const [mediaData, setMediaData] = useState(undefined);

  const url = "http://mrapi.mruns.com/wp-json/wp/v2/media/" + props.mediaId;

  useEffect(() => {
    axious
      .get(url)
      .then((reponse) => {
        setMediaData(reponse.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "Media Data Not forund for " +
            props.mediaId +
            " media id. using defult image."
        );
        setLoading(false);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "50",
      }}>
      {loading ? <Loading /> : ""}

      {loading === false && mediaData === undefined && (
        <CardMedia>
          <img
            src='http://mrapi.mruns.com/wp-content/uploads/2020/01/83798790_2842918695768253_5984271632523329536_o-480x162.jpg'
            alt='MRuns Logo'
          />
        </CardMedia>
      )}
      {mediaData !== undefined && (
        <CardMedia style={{ textAlign: "cetner" }}>
          <img
            src={mediaData.media_details.sizes.medium.source_url}
            alt={mediaData.slug}
          />
        </CardMedia>
      )}
    </div>
  );
};

export default PostMediaCard;
