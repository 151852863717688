import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Loading from "./Loading";

import axious from "axios";
import PostMediaCard from "./PostMediaCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  header: {
    justifyContent: "center",
    textAlign: "center",
  },
}));

const PostDetail = (props) => {
  const classes = useStyles();
  const [detailData, setDetailData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(props.match.params.id);
  useEffect(() => {
    axious
      .get(
        "http://mrapi.mruns.com/wp-json/wp/v2/posts?slug=" +
          props.match.params.id
      )
      .then((reponse) => {
        setDetailData(reponse.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
      });
  }, []);
  console.log(detailData);
  return (
    <>
      <Container className={classes.cardGrid} maxWidth='md'>
        <Grid container spacing={2} direction='row' alignItems='stretch'>
          {loading ? <Loading /> : ""}
          {detailData.map((data) => (
            <Grid item key={data.id} xs={12}>
              <Card className={classes.card}>
                <CardContent className={classes.header}>
                  <Typography gutterBottom variant='h4'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.title.rendered,
                      }}
                    />
                  </Typography>
                </CardContent>
                <CardContent className={classes.header}>
                  <PostMediaCard mediaId={data.featured_media} />
                </CardContent>

                <CardContent className={classes.cardContent}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.content.rendered,
                    }}
                  />
                </CardContent>
                <CardActions>
                  <Button size='small' color='primary'>
                    <Link to='/'>Home</Link>
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default PostDetail;
