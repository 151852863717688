import React, { useState, useEffect } from "react";

import axious from "axios";
import Loading from "./Loading";

import StravaReviewCard from "./StravaDataCard";
import { Grid } from "@material-ui/core";

const ViewStravaData = () => {
  const [stravaData, setStravaData] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = `http://www.silwal.com/api/strava`;
  useEffect(() => {
    axious.get(url).then(function (reponse) {
      setStravaData(reponse.data);
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Grid container tyle={{ display: "flex", justifyContent: "center" }}>
        <Grid item sm={3} />
        <Grid item xs={12} sm={6}>
          {loading ? <Loading /> : ""}
        </Grid>
        <Grid item sm={3} />
      </Grid>
      {stravaData !== undefined &&
        stravaData.map((data) => (
          <Grid container justify='center' key={data.id}>
            <Grid item sm={3} />
            <Grid item xs={12} sm={6}>
              <StravaReviewCard data={data} />
            </Grid>
            <Grid item sm={3} />
          </Grid>
        ))}
    </>
  );
};

export default ViewStravaData;
