import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import Moment from "react-moment";
import MapData from "./MapData";
import SplitTable from "./SplitTable";

import momentduration from "moment-timezone";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  media: {
    height: "450px",
    width: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function StravaReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const subHeaderInfo = () => {
    return (
      <>
        <Moment format='MM/DD/YYYY HH:MM:SS'>{props.data.start_date}</Moment>
        <Typography variant='body2' color='textSecondary' component='p'>
          Distance: {parseFloat(props.data.distance * 0.000621371).toFixed(1)}
          miles/ {parseFloat(props.data.distance * 0.001).toFixed(1)} KMs
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          Activity Time:
          {momentduration

            .duration(props.data.elapsed_time, "second")
            .format("h [hrs], m [min], s [sec]")}
        </Typography>
      </>
    );
  };

  return (
    <Paper elevation={10} style={{ alignItems: "center", width: "100%" }}>
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              aria-label='recipe'
              className={classes.avatar}
              src='https://dgalywyr863hv.cloudfront.net/pictures/athletes/521253/270301/2/medium.jpg'
            />
          }
          action={
            <IconButton aria-label='settings'>
              <MoreVertIcon />
            </IconButton>
          }
          title={props.data.name}
          subheader={subHeaderInfo()}
        />
        {props.data.manual ? (
          ""
        ) : (
          <MapData
            start_lat={
              props.data.start_latlng ? props.data.start_latlng[0] : ""
            }
            start_lng={
              props.data.start_latlng ? props.data.start_latlng[1] : ""
            }
            polyline={props.data.map.summary_polyline}
          />
        )}
        <CardContent>
          <Typography
            variant='body2'
            color='textSecondary'
            component='p'></Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'>
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <SplitTable id={props.data.id} />
          </CardContent>
        </Collapse>
      </Card>
    </Paper>
  );
}
