import React from "react";

import PostsViews from "./PostsView";
const DiscountCode = () => {
  console.log("****");
  return (
    <div>
      <PostsViews urlProp='categories=72' />
    </div>
  );
};

export default DiscountCode;
