import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import Link from "@material-ui/core/Link";
const Footer = () => {
  return (
    <Container style={{ paddingTop: "20" }} maxWidth='md'>
      <Grid container spacing={2} direction='row' alignItems='stretch'>
        <Grid xs={12} style={{ display: "center" }}>
          <Typography variant='body2' color='textSecondary' align='center'>
            {"Find @MarathonRuns At"}
            <br />
            <IconButton aria-label='Home' color='inherit'>
              <Link href='https://www.facebook.com/marathonRuns/' target='_new'>
                <FacebookIcon fontSize='large' />
              </Link>
            </IconButton>
            <IconButton aria-label='Home' color='inherit'>
              <Link
                href='https://www.instagram.com/marathonruns/'
                target='_new'>
                <InstagramIcon fontSize='large' />
              </Link>
            </IconButton>
            <IconButton aria-label='Home' color='inherit'>
              <Link
                href='https://www.instagram.com/marathonruns/'
                target='_new'>
                <TwitterIcon fontSize='large' />
              </Link>
            </IconButton>
            <IconButton aria-label='Home' color='inherit'>
              <Link href='https://www.YouTube.com/marathonruns/' target='_new'>
                <YouTubeIcon fontSize='large' />
              </Link>
            </IconButton>
            <IconButton aria-label='Home' color='inherit'>
              <Link
                href='https://www.soundcloud.com/marathonruns/'
                target='_new'>
                <RecordVoiceOverIcon fontSize='large' />
              </Link>
            </IconButton>
            <br />
            {"© "}
            <Link color='inherit' href='http://mruns.com'>
              MRuns.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
