import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Loading from "./Loading";
import Tooltip from "@material-ui/core/Tooltip";

import axious from "axios";
import PostMediaCard from "./PostMediaCard";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CardHeader from "@material-ui/core/CardHeader";
import Moment from "react-moment";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: "#3f50b5",
  },
}));

const PostsViews = (props) => {
  const classes = useStyles();

  var urlParm = props.urlProp ? props.urlProp : "";
  const [mrData, setMrData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [headerInfo, setHeaderInfo] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  useEffect(() => {
    const url = `http://www.mrapi.mruns.com/wp-json/wp/v2/posts?page=${pageNumber}&per_page=15&${urlParm}`;
    axious
      .get(url)
      .then((response) => {
        setMrData(response.data);
        setHeaderInfo(response.headers);
        setLoading(false);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
      });
  }, [pageNumber]);

  return (
    <Container className={classes.cardGrid} maxWidth='md'>
      <Grid container spacing={2} direction='row' alignItems='stretch'>
        {loading !== true && (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={parseInt(headerInfo["x-wp-totalpages"])}
              variant='outlined'
              color='primary'
              onChange={(event, page) => setPageNumber(page)}
            />
          </Grid>
        )}
        {loading === true && <Loading />}
        {mrData.map((data) => (
          <Grid
            item
            key={data.id}
            xs={12}
            sm={6}
            md={4}
            style={{ display: "flex" }}>
            <Paper elevation={5} component={Card} style={{ width: "100%" }}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    <Avatar aria-label='recipe' className={classes.avatar}>
                      MR
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label='settings'>
                      <Link
                        to={{
                          pathname: `/detail/${data.slug}`,
                          postData: data,
                        }}>
                        <MoreVertIcon />
                      </Link>
                    </IconButton>
                  }
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.title.rendered,
                      }}
                    />
                  }
                  subheader={<Moment format='DD/MM/YYYY'>{data.date}</Moment>}
                />
                <PostMediaCard mediaId={data.featured_media} />
                <CardContent className={classes.cardContent}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.excerpt.rendered,
                    }}
                  />
                </CardContent>
                <CardActions>
                  <HtmlTooltip
                    title={
                      <>
                        <strong>Summary:</strong>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.content.rendered.substring(0, 500),
                          }}
                        />
                        <strong>Click View for More Detail</strong>
                      </>
                    }>
                    <Button size='small' color='primary'>
                      <Link
                        to={{
                          pathname: `/detail/${data.slug}`,
                          postData: data,
                        }}>
                        View
                      </Link>
                    </Button>
                  </HtmlTooltip>
                </CardActions>
              </Card>
            </Paper>
          </Grid>
        ))}
        {loading !== true && (
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={parseInt(headerInfo["x-wp-totalpages"])}
              variant='outlined'
              color='primary'
              onChange={(event, page) => setPageNumber(page)}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PostsViews;
