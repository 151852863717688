import React, { useState, useEffect } from "react";

import axious from "axios";
import Loading from "./Loading";

import { Grid, IconButton, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Pagination from "@material-ui/lab/Pagination";
import FacebookIcon from "@material-ui/icons/Facebook";
import Link from "@material-ui/core/Link";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  media: {
    height: 100,
    width: 150,
  },
});

const RunSignupCalendar = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(true);
  const [headerInfo, setHeaderInfo] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [stateCode, setStateCode] = useState();

  useEffect(() => {
    //const url = `http://www.silwal.com/api/runsignupcalendar?pageNumber=${pageNumber}&stateCode=${stateCode}`;
    const url = `http://localhost:8080/runsignupcalendar?pageNumber=${pageNumber}&stateCode=${stateCode}`;
    axious
      .get(url)
      .then(function (response) {
        console.log(response.data.races.race);
        setHeaderInfo(response.headers);
        setData(response.data.races.race);

        setLoading(false);
      })
      .catch((error) => {
        console.log("ERROR");
        console.log(error);
      });
  }, [pageNumber]);

  const classes = useStyles();

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 0,
          width: "100%",
        }}>
        <Grid item sm={3} />
        <Grid item xs={12} sm={6}>
          {loading ? <Loading /> : ""}
        </Grid>
        <Grid item sm={3} />
      </Grid>
      {loading !== true && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 10,
            paddingTop: 10,
          }}>
          <Pagination
            count={pageNumber + 5}
            variant='outlined'
            color='primary'
            onChange={(event, page) => setPageNumber(page)}
          />
        </Grid>
      )}
      {data !== undefined &&
        data.map((raceData) => (
          <Grid
            container
            justify='center'
            key={data.id}
            spacing='5'
            style={{ width: "100%" }}>
            <Grid item sm={1} />
            <Grid item xs={12} sm={10}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container>
                    <Grid item xs sm={2}>
                      {raceData.logo_url ? (
                        <CardMedia
                          className={classes.media}
                          image={raceData.logo_url}
                          title={raceData.name}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs sm={9}>
                      <Typography variant='h5' component='h2'>
                        {raceData.name}
                      </Typography>
                      <Grid
                        container
                        direction='column'
                        justify='flex-start'
                        alignItems='flex-start'>
                        <Grid item xs sm={2}>
                          <Typography color='textSecondary'>
                            {raceData.next_end_date}
                          </Typography>
                        </Grid>
                        <Grid item xs sm={4}>
                          <Typography variant='subtitle2'>
                            {raceData.address.street},{" "}
                            {raceData.address.street2}
                          </Typography>
                          <Typography variant='subtitle2'>
                            {raceData.address.city}, {raceData.address.state}
                          </Typography>
                        </Grid>
                        <Grid item xs sm={2}>
                          <CardActions>
                            <Button size='small' variant='outlined'>
                              <a
                                href={raceData.url}
                                target='_new'
                                style={{ textDecoration: "none" }}>
                                Register
                              </a>
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='flex-end'>
                        <Grid item xs>
                          {raceData.is_registration_open ? (
                            <CheckCircleIcon style={{ fill: "green" }} />
                          ) : (
                            <CancelIcon style={{ fill: "red" }} />
                          )}
                        </Grid>
                        <Grid item xs>
                          {raceData.fb_page_id ? (
                            <IconButton aria-label='Home' color='inherit'>
                              <Link
                                href={
                                  "https://www.facebook.com/" +
                                  raceData.fb_page_id
                                }
                                target='_new'>
                                <FacebookIcon fontSize='large' />
                              </Link>
                            </IconButton>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1a-content'
                      id='panel1a-header'>
                      <Typography>Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant='body2' component='p'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: raceData.description,
                          }}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={1} />
          </Grid>
        ))}
      {loading !== true && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: 10,
            paddingTop: 10,
          }}>
          <Pagination
            count={pageNumber + 5}
            variant='outlined'
            color='primary'
            onChange={(event, page) => setPageNumber(page)}
          />
        </Grid>
      )}
    </>
  );
};

export default RunSignupCalendar;
