import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Slider from "react-slick";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
}));

const ImageSlider = () => {
  const classes = useStyles();

  var settings = {
    dots: true,
    arrows: true,
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  return (
    <div className={classes.heroContent}>
      <Container maxWidth='sm'>
        <Slider {...settings}>
          <div>
            <img src='http://mrapi.mruns.com/wp-content/uploads/2020/06/MRuns_3.jpg' />
          </div>
          <div>
            <img src='http://mrapi.mruns.com/wp-content/uploads/2020/06/MRuns_2.jpg' />
          </div>
          <div>
            <img src='http://mrapi.mruns.com/wp-content/uploads/2020/06/MRuns_1.jpg' />
          </div>
        </Slider>
      </Container>
    </div>
  );
};

export default ImageSlider;
