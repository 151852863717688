import React from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";

import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import Fade from "@material-ui/core/Fade";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    paddingBottom: 60,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("xs")]: {
      // display: "none",
    },
  },
}));

export default function NavBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      TransitionComponent={Fade}>
      <MenuItem onClick={handleMenuClose}>
        <Link to='/'>Home</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to='/photos'>Photos</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to='/podcast'>Podcast</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to='/strava'>Running Log</Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link to='/discount'>Discount Code</Link>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position='fixed'>
        <Toolbar>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            onClick={handleProfileMenuOpen}
            aria-label='open drawer'>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant='h6' noWrap>
            MRuns.com
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder='Search…'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}
          <div className={classes.grow} />
          <div className={classes.sectionMobile}>
            <IconButton aria-label='Home' color='inherit'>
              <Link to='/' style={{ color: "#FFF" }}>
                <HomeIcon />
              </Link>
            </IconButton>

            <IconButton aria-label='MRuns Photos' color='inherit'>
              <Link to='/photos' style={{ color: "#FFF" }}>
                <PhotoCameraIcon />
              </Link>
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <Link to='/podcast' style={{ color: "#FFF" }}>
                <RecordVoiceOverIcon />
              </Link>
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <Link to='/strava' style={{ color: "#FFF" }}>
                <DirectionsRunIcon />
              </Link>
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <Link to='/discount' style={{ color: "#FFF" }}>
                <MonetizationOnIcon />
              </Link>
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <Link to='/runsignup' style={{ color: "#FFF" }}>
                <EventAvailableIcon />
              </Link>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
}
