import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";

import PostsViews from "./PostsView";

import ImageSlider from "./ImageSlider";

export default function MainView() {
  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <ImageSlider />
        <PostsViews />
        <div style={{ textAlign: "center", justifyContent: "center" }}></div>
      </main>
    </React.Fragment>
  );
}
