import React from "react";

import { Map, TileLayer, Polyline } from "react-leaflet";

import L from "@mapbox/polyline";

// const getMap = (props) => {
//   //var map = polyUtil.setView([33.333132, -86.817016], 11);
//   var map = leaflet.map("map").setView([33.333132, -86.817016], 11);
//   return <div>{props.start_lat}</div>;
// };

const MapData = (props) => {
  //const L = require("polyline-encoded");
  // L.polyPolyline.formfromEncoded(props.polyline).getLatLngs();

  const runningMap = () => {
    if (props.polyline !== null || props.polyline !== "undefined") {
      var polyline = [];
      L.decode(props.polyline).map((data) => {
        if (
          data.length > 0 &&
          (data[0] !== null ||
            data[0] !== "undefined" ||
            data[1] !== null ||
            data[1] !== "undefined")
        )
          var polylines = [data[0], data[1]];
        polyline.push(polylines);
      });
      return polyline;
    }
  };

  return (
    <div id='map'>
      <Map
        {...(props.start_lat ? props.start_lat : "33.543682")}
        {...(props.start_lng ? props.start_lng : "-86.779633")}
        center={[props.start_lat, props.start_lng]}
        zoom='16'
        style={{
          height: "450px",
          width: "100%",
          padding: "0",
          margin: "0",
        }}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {props.polyline !== null || props.polyline !== "undefined" ? (
          <Polyline
            key='count++'
            positions={runningMap()}
            color='green'
            weight='5'
            opacity='0.7'
            lineJoin='round'
          />
        ) : (
          "MAP NOT FOUND"
        )}
      </Map>
    </div>
  );
};
export default MapData;
